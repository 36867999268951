export default function About({ mode }) {
	const currentMode = {
		color: mode === 'dark' ? 'white' : '#042743',
		backgroundColor: mode === 'dark' ? '#282D32' : 'white',
	};

	return (
		<div className="container">
			<h1
				className="my-3"
				style={{
					color: mode === 'dark' ? 'white' : '#0427430',
					fontWeight: '600',
				}}
			>
				About Us
			</h1>
			<div className="accordion" id="accordionExample">
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingOne">
						<button
							className="accordion-button"
							type="button"
							style={currentMode}
							data-bs-toggle="collapse"
							data-bs-target="#collapseOne"
							aria-expanded="true"
							aria-controls="collapseOne"
						>
							Analyze Your text
						</button>
					</h2>
					<div
						id="collapseOne"
						className="accordion-collapse collapse show"
						aria-labelledby="headingOne"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body" style={currentMode}>
							Text Utils gives you a way to analyze your text quickly and
							efficiently. Be it word count, character count or
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingTwo">
						<button
							className="accordion-button collapsed"
							style={currentMode}
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseTwo"
							aria-expanded="false"
							aria-controls="collapseTwo"
						>
							Free to use
						</button>
					</h2>
					<div
						id="collapseTwo"
						className="accordion-collapse collapse"
						aria-labelledby="headingTwo"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body" style={currentMode}>
							TextUtils is a free character counter tool that provides instant
							character count & word count statistics for a given text.
							TextUtils reports the number of words and characters. Thus it is
							suitable for writing text with word/ character limit.
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingThree">
						<button
							className="accordion-button collapsed"
							style={currentMode}
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseThree"
							aria-expanded="false"
							aria-controls="collapseThree"
						>
							Browser Compatible
						</button>
					</h2>
					<div
						id="collapseThree"
						className="accordion-collapse collapse"
						aria-labelledby="headingThree"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body" style={currentMode}>
							This word counter software works in any web browsers such as
							Chrome, Firefox, Internet Explorer, Safari, Opera. It suits to
							count characters in facebook, blog, books, excel document, pdf
							document, essays, etc.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
